import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import { Box, FormControl, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import "./App.css";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ForwardIcon from "@material-ui/icons/Forward";
import Rating from "@material-ui/lab/Rating";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Carousel from "./PictureCarousel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { data } from "./Data";
import logo from "./hopandgologo.png";

const normalFontSize = 15;
const accessibleFontSize = 22;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={"up"} ref={ref} {...props} />;
});

export default function SimpleSelect() {
    const [location, setLocation] = React.useState("");
    const [category, setCategory] = React.useState("");
    const [price, setPrice] = React.useState("");
    const [rating, setRating] = React.useState(0);
    const [images, setImages] = React.useState(["hi"]);
    const [imageTitle, setImageTitle] = React.useState("Loading");
    // const light = theme.palette.type === 'light';

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };
    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };
    const handlePriceChange = (event) => {
        setPrice(event.target.value);
    };
    const handleRatingChange = (event) => {
        setRating(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const [venue, setVenue] = React.useState(0);

    var Bing = require("node-bing-api")({ accKey: "f09039d780e9496dafbbe941177cb386", rootUri: "https://api.bing.microsoft.com/v7.0/" }); //({ accKey: "5c7e6ea23273433db3dd24a3b6f08689" });

    const handleClickOpen = () => {
        setOpen(true);
        var indices = [...Array(data.length).keys()];
        var filteredIndices = indices.filter(
            (index) =>
                (data[index].Location === location || location === "") &&
                (data[index].Category === category || category === "") &&
                (data[index].PriceRange === price || price === "") &&
                Math.round(data[index].Ratings * 2) / 2 >= rating
        );
        if (filteredIndices.length === 0) {
            filteredIndices = indices.filter(
                (index) =>
                    (data[index].Location === location || location === "") &&
                    (data[index].Category === category || category === "") &&
                    (data[index].PriceRange === price || price === "")
            );
        }
        if (filteredIndices.length === 0) {
            filteredIndices = indices.filter(
                (index) => (data[index].Location === location || location === "") && (data[index].Category === category || category === "")
            );
        }
        if (filteredIndices.length === 0) {
            filteredIndices = indices.filter((index) => data[index].Location === location || location === "");
        }
        var venueIndex = filteredIndices[Math.floor(Math.random() * filteredIndices.length)];
        setVenue(venueIndex);

        var venueName = data[venueIndex].Name;
        var locationName = data[venueIndex].Location;
        var searchQuery = venueName + " " + locationName;

        setImages([""]);
        setImageTitle("Loading");
        Bing.images(
            searchQuery,
            {
                count: 5, // Number of results (max 50)
                adult: "Strict",
            },
            function (error, res, body) {
                setImages(body.value.map((obj) => obj.contentUrl));
                setImageTitle(venueName);
            }
        );
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [fontSize, setFontSize] = React.useState(normalFontSize);

    const handleChange = (event) => {
        if (event.target.checked) {
            setFontSize(accessibleFontSize);
        } else {
            setFontSize(normalFontSize);
        }
    };

    // const font =  "'Merienda', cursive";
    const font = "'Calistoga', cursive";
    const theme = createMuiTheme({
        typography: {
            fontFamily: font,
            // fontWeightRegular: 1000,
            color: "white",
            fontSize: fontSize,
            button: {
                textTransform: "none",
                color: "white",
            },
        },
    });

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: "2vh", // theme.spacing(1),
            minWidth: 220,
            variant: "subtitle1",
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        appBar: {
            position: "relative",
            backgroundColor: "#552E62",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
            whiteSpace: "pre-wrap",
        },
        avoidwrap: {
            display: "inline-block",
            paddingRight: "4vh",
            fontSize: { fontSize },
        },
        avoidwrapright: {
            display: "inline-block",
            paddingRight: "2vh",
            fontSize: { fontSize },
        },
        avoidwrapcenter: {
            display: "inline-block",
            paddingRight: "2vh",
            paddingLeft: "2vh",
            fontSize: { fontSize },
        },
    }));

    const classes = useStyles();

    return (
        <MuiThemeProvider theme={theme}>
            <Grid
                container
                spacing={2}
                direction="row"
                align="center"
                alignItems="center"
                justify="center"
                style={{ width: "100%", minHeight: "40vh", marginTop: "20vh", marginRight: "0px", marginLeft: "0px", background: "rgba(204,150,197,0.90)" }}
            >
                <Grid item xs={12}>
                    <img src={logo} alt={"logo"} style={{ height: "15vh", width: "15hv" }} />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="inputLable1">Location</InputLabel>
                        <Select value={location} onChange={handleLocationChange} label="Location">
                            <MenuItem value="">
                                <em>Any</em>
                            </MenuItem>
                            {[...new Set(data.map((item) => item.Location))].sort().map((location) => {
                                return <MenuItem value={location}>{location}</MenuItem>;
                            })}
                        </Select>
                        <FormHelperText>Choose a location</FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Category</InputLabel>
                        <Select value={category} onChange={handleCategoryChange} label="Category">
                            <MenuItem value="">
                                <em>Any</em>
                            </MenuItem>
                            {[...new Set(data.map((item) => item.Category))].sort().map((category) => {
                                return <MenuItem value={category}>{category}</MenuItem>;
                            })}
                        </Select>
                        <FormHelperText>Choose a category</FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel>Price</InputLabel>
                        <Select value={price} onChange={handlePriceChange} label="Price">
                            <MenuItem value="">
                                <em>Any</em>
                            </MenuItem>
                            <MenuItem value={"£"}>£</MenuItem>
                            <MenuItem value={"££"}>££</MenuItem>
                            <MenuItem value={"£££"}>£££</MenuItem>
                        </Select>
                        <FormHelperText>Set your budget</FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Box
                            display="flex"
                            alignItems="center"
                            padding={0}
                            border={1}
                            borderRadius={4}
                            borderColor="#9F7699"
                            style={{ height: fontSize === normalFontSize ? "54px" : "67.56px" }}
                        >
                            <Rating
                                border={2}
                                name="simple-controlled"
                                value={rating}
                                onChange={handleRatingChange}
                                // size="medium"
                                style={{
                                    position: "absolute",
                                    left: "50%",
                                    transform: "translate(-50%, 0%)",
                                    fontSize: fontSize === normalFontSize ? "34px" : "40.56px",
                                }}
                            />
                        </Box>
                        <FormHelperText>Minimum rating</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.button}
                        endIcon={<ForwardIcon />}
                        onClick={handleClickOpen}
                        style={{ marginBottom: "1vh" }}
                    >
                        Find a Venue
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                // fullScreen
                maxWidth={false}
                TransitionComponent={Transition}
                transitionDuration={500}
                onClose={handleClose}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle style={{ padding: "0px" }}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="subtitle1" style={{ size: "10vh", flex: 1 }}>
                                <span className={classes.avoidwrap}> Location: {location !== "" ? location : "any"} </span>
                                <span className={classes.avoidwrap}> Type: {category !== "" ? category : "any"} </span>
                                <span className={classes.avoidwrap}> Price: {price !== "" ? price : "any"} </span>
                                <span className={classes.avoidwrapright}> Minimum Rating: {rating} </span>
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>
                <DialogContent style={{ marginLeft: "0px", marginRight: "0px", padding: "0px" }}>
                    <Grid container spacing={0} direction="row" align="center" alignItems="center" justify="center">
                        <Grid item xs={12} style={{ maxHeight: "5vh", marginTop: "3vh" }}></Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{ paddingBottom: "2vh" }}>
                                {data[venue].Name}
                            </Typography>
                            <Typography variant="h6">
                                <span className={classes.avoidwrapcenter}>
                                    {data[venue].Category === "Restaurant" ? (data[venue].Cuisine !== "Restaurant" ? data[venue].Cuisine + " " : "") : ""}
                                    {data[venue].Category}
                                </span>
                                •{data[venue].PriceRange ? <span className={classes.avoidwrapcenter}> {data[venue].PriceRange} </span> : ""}
                                {data[venue].PriceRange ? "•" : ""}
                                <span className={classes.avoidwrapcenter}>
                                    <Rating style={{ verticalAlign: "middle" }} value={Number(data[venue].Ratings)} precision={0.5} size={"large"} readOnly />
                                </span>
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    window.open(
                                        "https://www.vouchercodes.co.uk/search/?q=" + data[venue].Name + " " + data[venue].Location + "&isStudent=true",
                                        "_blank"
                                    )
                                }
                                style={{ margin: "2vh" }}
                            >
                                Coupon Codes
                            </Button>
                            <Carousel images={images} title={imageTitle} />
                            <iframe
                                src={
                                    "https://www.google.com/maps?q=" +
                                    data[venue].Name +
                                    " " +
                                    data[venue].Address +
                                    " " +
                                    data[venue].Location +
                                    "&output=embed"
                                }
                                width="100%"
                                height="450"
                                frameBorder="0"
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                                title="Venue Location"
                            ></iframe>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        I'm not feeling it
                        <CloseIcon style={{ paddingLeft: "0.5vw" }} />
                    </Button>
                </DialogActions>
            </Dialog>
            <FormControlLabel
                control={<Switch checked={fontSize.checkedA} onChange={handleChange} name="checkedA" />}
                label="Accessibility Mode"
                style={{ padding: "15px" }}
            />
        </MuiThemeProvider>
    );
}
