import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Sky from "react-sky";

ReactDOM.render(
    <React.StrictMode>
        <Sky
            images={{
                //space
                // 0: "https://cdn-icons-png.flaticon.com/256/124/124574.png",
                // 1: "https://cdn-icons-png.flaticon.com/256/124/124570.png",
                // 2: "https://cdn-icons-png.flaticon.com/256/124/124567.png",
                // 3: "https://cdn-icons-png.flaticon.com/256/124/124560.png",
                // 4: "https://cdn-icons-png.flaticon.com/256/124/124559.png",
                // 5: "https://cdn-icons-png.flaticon.com/256/124/124582.png",
                // 6: "https://cdn-icons-png.flaticon.com/256/124/124558.png",
                // 7: "https://cdn-icons-png.flaticon.com/256/124/124588.png",
                // 8: "https://cdn-icons-png.flaticon.com/256/124/124542.png",
                // 9: "https://cdn-icons-png.flaticon.com/256/124/124569.png",
                // 10: "https://cdn-icons-png.flaticon.com/256/124/124573.png",
                // 11: "https://cdn-icons-png.flaticon.com/256/124/124586.png",
                // 12: "https://cdn-icons-png.flaticon.com/256/124/124548.png",
                // 13: "https://cdn-icons-png.flaticon.com/256/124/124555.png",
                // //animals
                // 14: "https://cdn-icons-png.flaticon.com/256/1198/1198051.png",
                // 15: "https://cdn-icons-png.flaticon.com/256/1198/1198052.png",
                // 16: "https://cdn-icons-png.flaticon.com/256/1198/1198053.png",
                // 17: "https://cdn-icons-png.flaticon.com/256/1198/1198053.png",
                // 18: "https://cdn-icons-png.flaticon.com/256/1198/1198056.png",
                // 19: "https://cdn-icons-png.flaticon.com/256/1198/1198057.png",
                // 20: "https://cdn-icons-png.flaticon.com/256/1198/1198059.png",
                // 21: "https://cdn-icons-png.flaticon.com/256/1198/1198060.png",
                // 22: "https://cdn-icons-png.flaticon.com/256/1198/1198062.png",
                // 23: "https://cdn-icons-png.flaticon.com/256/1198/1198063.png",
                // 24: "https://cdn-icons-png.flaticon.com/256/1198/1198065.png",
                // 25: "https://cdn-icons-png.flaticon.com/256/1198/1198053.png",
                // 26: "https://cdn-icons-png.flaticon.com/256/1198/1198068.png",
                // 27: "https://cdn-icons-png.flaticon.com/256/1198/1198069.png",
                // 28: "https://cdn-icons-png.flaticon.com/256/1198/1198070.png",
                // 29: "https://cdn-icons-png.flaticon.com/256/1198/1198073.png",
                // 30: "https://cdn-icons-png.flaticon.com/256/1198/1198075.png",
                // 31: "https://cdn-icons-png.flaticon.com/256/1198/1198076.png",
                // 32: "https://cdn-icons-png.flaticon.com/256/1198/1198079.png",
                // //autumn
                // 33: "https://cdn-icons-png.flaticon.com/256/1230/1230864.png",
                // 34: "https://cdn-icons-png.flaticon.com/256/1230/1230865.png",
                // 35: "https://cdn-icons-png.flaticon.com/256/1230/1230867.png",
                // 36: "https://cdn-icons-png.flaticon.com/256/1230/1230868.png",
                // 37: "https://cdn-icons-png.flaticon.com/256/1230/1230869.png",
                // 38: "https://cdn-icons-png.flaticon.com/256/1230/1230871.png",
                // 39: "https://cdn-icons-png.flaticon.com/256/1230/1230882.png",
                // 40: "https://cdn-icons-png.flaticon.com/256/1230/1230875.png",
                // //tech
                // 41: "https://cdn-icons-png.flaticon.com/256/141/141073.png",
                // 42: "https://cdn-icons-png.flaticon.com/256/141/141070.png",
                // 43: "https://cdn-icons-png.flaticon.com/256/141/141009.png",
                // 44: "https://cdn-icons-png.flaticon.com/256/140/140993.png",
                // 45: "https://cdn-icons-png.flaticon.com/256/141/141106.png",
                // 46: "https://cdn-icons-png.flaticon.com/256/141/141015.png",
                // 47: "https://cdn-icons-png.flaticon.com/256/141/141099.png",
                // 48: "https://cdn-icons-png.flaticon.com/256/141/141008.png",
                // 49: "https://cdn-icons-png.flaticon.com/256/141/141036.png",
                // 50: "https://cdn-icons-png.flaticon.com/256/135/135728.png",
                //food
                // 51: "https://cdn-icons-png.flaticon.com/256/135/135687.png",
                // 52: "https://cdn-icons-png.flaticon.com/256/135/135715.png",
                // 53: "https://cdn-icons-png.flaticon.com/256/135/135628.png",
                // 54: "https://cdn-icons-png.flaticon.com/256/135/135591.png",
                // 55: "https://cdn-icons-png.flaticon.com/256/135/135702.png",
                // 56: "https://cdn-icons-png.flaticon.com/256/135/135644.png",
                // 57: "https://cdn-icons-png.flaticon.com/256/135/135629.png",
                // 58: "https://cdn-icons-png.flaticon.com/256/135/135630.png"

                0: "https://cdn-icons-png.flaticon.com/256/135/135687.png",
                1: "https://cdn-icons-png.flaticon.com/256/135/135715.png",
                2: "https://cdn-icons-png.flaticon.com/256/135/135628.png",
                3: "https://cdn-icons-png.flaticon.com/256/135/135591.png",
                4: "https://cdn-icons-png.flaticon.com/256/135/135702.png",
                5: "https://cdn-icons-png.flaticon.com/256/135/135644.png",
                6: "https://cdn-icons-png.flaticon.com/256/135/135629.png",
                7: "https://cdn-icons-png.flaticon.com/256/135/135630.png",
                8: "https://cdn-icons-png.flaticon.com/256/2912/2912292.png",
                9: "https://cdn-icons-png.flaticon.com/256/3075/3075929.png",
                10: "https://cdn-icons-png.flaticon.com/256/4321/4321636.png",
                11: "https://cdn-icons-png.flaticon.com/256/2965/2965567.png",
                12: "https://cdn-icons-png.flaticon.com/256/135/135759.png",
                13: "https://cdn-icons-png.flaticon.com/256/135/135542.png",
                14: "https://cdn-icons-png.flaticon.com/256/135/135584.png",
                15: "https://cdn-icons-png.flaticon.com/256/135/135594.png",
                16: "https://cdn-icons-png.flaticon.com/256/135/135701.png",
                17: "https://cdn-icons-png.flaticon.com/256/135/135755.png",
                18: "https://cdn-icons-png.flaticon.com/256/135/135559.png",
                19: "https://cdn-icons-png.flaticon.com/256/135/135708.png",
                20: "https://cdn-icons-png.flaticon.com/256/135/135556.png",
                21: "https://cdn-icons-png.flaticon.com/256/135/135590.png",
                22: "https://cdn-icons-png.flaticon.com/256/135/135633.png",
                23: "https://cdn-icons-png.flaticon.com/256/135/135699.png",
                24: "https://cdn-icons-png.flaticon.com/256/135/135652.png",
                25: "https://cdn-icons-png.flaticon.com/256/135/135598.png",
                26: "https://cdn-icons-png.flaticon.com/256/135/135700.png",
                27: "https://cdn-icons-png.flaticon.com/256/135/135727.png",
                28: "https://cdn-icons-png.flaticon.com/256/135/135609.png",
                29: "https://cdn-icons-png.flaticon.com/256/135/135587.png",
                30: "https://cdn-icons-png.flaticon.com/256/135/135572.png",
                31: "https://cdn-icons-png.flaticon.com/256/135/135636.png",
                32: "https://cdn-icons-png.flaticon.com/256/135/135689.png",
            }}
            how={140} /* Pass the number of images Sky will render chosing randomly */
            time={20} /* time of animation */
            size={"120px"} /* size of the rendered images */
            background={"#FFFFFF"} /* color of background */
        />
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
