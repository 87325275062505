import React from "react";
import Carousel from "react-material-ui-carousel";
import autoBind from "auto-bind";
import { CardMedia } from "@material-ui/core";

const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

function Picture(props) {
    return (
        <CardMedia
            component="img"
            // alt={props.item.description}
            // className={classes.media}
            height={Math.min(vh * 0.5, 500)}
            image={props.item}
            title={props.title}
            style={{ maxWidth: Math.min(vw * 0.7, 550), marginLeft: "0px", marginRight: "0px", padding: "0px" }}
        />
    );
}

// const pictures = [
//     {
//         url: "https://mk0mytonhospice8m1bg.kinstacdn.com/wp-content/uploads/2018/09/Glow-in-the-Moonlight-2018-Blog-Header-min.jpg",
//         description: "party"
//     },
//     {
//         url: "https://www.warwicksu.com/pageassets/venues-events/events/_DSC3538.jpg",
//         description: "another party"
//     }
// ]

export default class MyProjectsExample extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            autoPlay: true,
            animation: "fade",
            indicators: true,
            timeout: 300,
            interval: 20000,
            navButtonsAlwaysVisible: false,
            navButtonsAlwaysInvisible: false,
        };

        autoBind(this);
    }

    render() {
        return (
            <div style={{ marginBottom: "5vh", color: "#494949", maxWidth: Math.min(vw * 0.8, 700), marginLeft: "0px", marginRight: "0px", padding: "0px" }}>
                {/* //, height: '20vh'}}> */}
                <Carousel
                    className="SecondExample"
                    autoPlay={this.state.autoPlay}
                    animation={this.state.animation}
                    indicators={this.state.indicators}
                    timeout={this.state.timeout}
                    navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
                    navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
                >
                    {this.props.images.map((url) => {
                        return <Picture item={url} title={this.props.title} />;
                    })}
                </Carousel>
            </div>
        );
    }
}
