export const data = [
    {
        Name: "The Kee Lee",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "71 Clarendon St",
    },
    {
        Name: "The Fox and Vivian",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pub",
        Address: "32 Clarendon Ave",
    },
    {
        Name: "Hayashi Japanese Restaurant",
        Location: "Leamington Spa",
        Ratings: "4.7",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Japanese",
        Address: "115 Warwick St",
    },
    {
        Name: "Oscars French Steakhouse and Bistro",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "French",
        Address: "39 Chandos St",
    },
    {
        Name: "Basement Browns - Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Pizza",
        Address: "77-79 Warwick St",
    },
    {
        Name: "Carluccio's - Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "1-3 Satchwell Court Royal Priors Shopping Centre In The Royal Priors",
    },
    {
        Name: "Giggling Squid Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Thai",
        Address: "Royal Priors Shopping Centre, 7 Satchwell Court In The Royal Priors",
    },
    {
        Name: "Birtelli's",
        Location: "Leamington Spa",
        Ratings: "4.9",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Pizza Takeaway",
        Address: "10 Clarendon Ave",
    },
    {
        Name: "Habano Restaurant Leamington",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pan-Latin",
        Address: "48 Warwick St",
    },
    {
        Name: "Libertine Burger",
        Location: "Leamington Spa",
        Ratings: "4.7",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Hamburger",
        Address: "106 Warwick St",
    },
    {
        Name: "Lotus Moon Thai Cuisine",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Thai",
        Address: "6 Guy St",
    },
    {
        Name: "Warwick Street Kitchen",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "102 Warwick St",
    },
    {
        Name: "Bistrot Pierre",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "French",
        Address: "28 Park St In The Royal Priors",
    },
    {
        Name: "Elma Restaurant",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Mediterranean",
        Address: "8-10 Augusta Pl",
    },
    {
        Name: "The Drawing Board",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "18 Newbold St",
    },
    {
        Name: "Turtle Bay Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Caribbean",
        Address: "Livery St In Regent Court Shopping Centre",
    },
    {
        Name: "Kayal",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "42 Regent St",
    },
    {
        Name: "YO! Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Japanese",
        Address: "Unit 12, Regent Court, Livery St In Regent Court Shopping Centre",
    },
    {
        Name: "Sabai Sabai",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Thai",
        Address: "41-43 Regent St",
    },
    {
        Name: "Ruby",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Cantonese",
        Address: "19 Park St",
    },
    {
        Name: "Been",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Chinese",
        Address: "29 Chandos St",
    },
    {
        Name: "Wofon",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Chinese",
        Address: "21 Regent Grove In Regent Court Shopping Centre",
    },
    {
        Name: "wagamama",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Japanese",
        Address: "94 Parade In Regent Court Shopping Centre",
    },
    {
        Name: "Eleven",
        Location: "Leamington Spa",
        Ratings: "4.8",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "Regent Pl",
    },
    {
        Name: "Zizzi",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Italian",
        Address: "6, Regent House In Regent Court Shopping Centre",
    },
    {
        Name: "Nando's Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Chicken",
        Address: "28 Livery St Unit SU9b, 10 Regent St In Regent Court Shopping Centre",
    },
    {
        Name: "Paprika Club",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "22 Regent St",
    },
    {
        Name: "Red Hot Mamma",
        Location: "Leamington Spa",
        Ratings: "4.8",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Pizza",
        Address: "108 Regent St In Regent Court Shopping Centre",
    },
    {
        Name: "Nana's Japanese",
        Location: "Leamington Spa",
        Ratings: "4.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Japanese",
        Address: "31 Regent St",
    },
    {
        Name: "Thai Elephant Restaurant",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Thai",
        Address: "20 Regent St",
    },
    {
        Name: "Tartine bistro & wine bar",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Winebar",
        Address: "30 Regent St",
    },
    {
        Name: "Tear-A-Byte",
        Location: "Leamington Spa",
        Ratings: "5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "2 High St",
    },
    {
        Name: "Rosie's",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "23 Livery St In Regent Court Shopping Centre",
    },
    {
        Name: "Bombay Restaurant",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "38-40 Regent St",
    },
    {
        Name: "The Garden Shed Cafe",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Vegan",
        Address: "Rear of Gaia 7, Regent Pl",
    },
    {
        Name: "Cafe Royal",
        Location: "Leamington Spa",
        Ratings: "4.7",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Cafe",
        Address: "70 Warwick St",
    },
    {
        Name: "Mem-saab",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "14 Clarendon Ave",
    },
    {
        Name: "Oriental Star",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Chinese",
        Address: "9 Parade",
    },
    {
        Name: "Il Piccolinos Ltd",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Italian",
        Address: "9 Spencer St",
    },
    {
        Name: "Aroma Cafe and Tapas Bar",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Tapasbar",
        Address: "47 Bedford St",
    },
    {
        Name: "Whittle's Restaurant",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "Binswood Ave",
    },
    {
        Name: "Pizza Express",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pizza",
        Address: "168 The Parade",
    },
    {
        Name: "Millennium Balti",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "41 Bath St",
    },
    {
        Name: "We Love Pizza",
        Location: "Leamington Spa",
        Ratings: "4.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Pizza",
        Address: "9 Regent Pl",
    },
    {
        Name: "HART + CO",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Grill",
        Address: "Hart&co, Augusta Pl",
    },
    {
        Name: "El Paso",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Mexican",
        Address: "12A Clarendon Ave",
    },
    {
        Name: "Kungfu",
        Location: "Leamington Spa",
        Ratings: "4.2",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Self Service",
        Address: "2a Livery St In Regent Court Shopping Centre",
    },
    {
        Name: "Five Rivers",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Modern Indian",
        Address: "20-22 Victoria Terrace",
    },
    {
        Name: "Prezzo Italian Restaurant Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "3.8",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Italian",
        Address: "25 Regent Grove",
    },
    {
        Name: "Gusto Ricco Cafe",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "63 - 65 Regent St",
    },
    {
        Name: "Dwarakamai",
        Location: "Leamington Spa",
        Ratings: "4.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "29 Augusta Pl",
    },
    {
        Name: "Simon's Place",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "13A Avenue, Station Approach",
    },
    {
        Name: "Gourmet Burger Kitchen (GBK)",
        Location: "Leamington Spa",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Hamburger",
        Address: "Unit 6, Regents Court, 18 Livery St In Regent Court Shopping Centre",
    },
    {
        Name: "Shawarma Grill",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Lebanese",
        Address: "8 Spencer St",
    },
    {
        Name: "Emperors",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Chinese",
        Address: "2 Bath Pl",
    },
    {
        Name: "Rustic Food",
        Location: "Leamington Spa",
        Ratings: "4.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Sandwich",
        Address: "129 Regent St",
    },
    {
        Name: "Casa Valle",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Charcuterie",
        Address: "44 Clarendon St",
    },
    {
        Name: "The Shire Grill",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "The Shire Grill, Chesterton Dr",
    },
    {
        Name: "Seven Seas Coco's Grill Restaurant",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Pakistani",
        Address: "21 Bath St",
    },
    {
        Name: "The Windmill Inn",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "Tachbrook Rd",
    },
    {
        Name: "Kitchen88 Chinese",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "6 Spencer St",
    },
    {
        Name: "The Moorings at Myton",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pub",
        Address: "Myton Rd",
    },
    {
        Name: "THE RISING SUN Chinese Takeaway",
        Location: "Leamington Spa",
        Ratings: "3.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "6A Lansdowne St",
    },
    {
        Name: "Wah Kee",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Chinese",
        Address: "50 High St",
    },
    {
        Name: "Happy Kitchen",
        Location: "Leamington Spa",
        Ratings: "3.9",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "39 Aylesford St",
    },
    {
        Name: "The Benjamin Satchwell",
        Location: "Leamington Spa",
        Ratings: "4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "112-114 The Parade",
    },
    {
        Name: "McDonald's Leamington Spa 2",
        Location: "Leamington Spa",
        Ratings: "3.7",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Fast Food",
        Address: "Queensway, Europa Way",
    },
    {
        Name: "Pepe's Piri Piri",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Fast Food",
        Address: "51 High St",
    },
    {
        Name: "Fizzy Moon Limited",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "35 Regent St",
    },
    {
        Name: "The Clarendon Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "44-46 Clarendon Ave",
    },
    {
        Name: "Côte Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "French",
        Address: "3 Regent St, Livery St In Regent Court Shopping Centre",
    },
    {
        Name: "La Coppola Ristorante & Oyster Bar",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Italian",
        Address: "14 Parade In The Royal Priors",
    },
    {
        Name: "Bill's Leamington Spa Restaurant",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "British",
        Address: "140-142 Parade",
    },
    {
        Name: "Las Iguanas - Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Latin American",
        Address: "Regent Court, 21 Livery St In Regent Court Shopping Centre",
    },
    {
        Name: "The Larder",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Western",
        Address: "23 Portland St",
    },
    {
        Name: "Bar + Block Steakhouse Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Grill",
        Address: "Regency Arcade, 148-150 Parade",
    },
    {
        Name: "Phoenix",
        Location: "Leamington Spa",
        Ratings: "4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Chinese",
        Address: "11 Euston Pl",
    },
    {
        Name: "The Star & Garter",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Gastropub",
        Address: "4-6 Warwick St",
    },
    {
        Name: "The Royal Pug",
        Location: "Leamington Spa",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Gastropub",
        Address: "141 Regent St",
    },
    {
        Name: "The Fat Pug",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pub",
        Address: "23 Guy's Cliffe Rd",
    },
    {
        Name: "The White Horse",
        Location: "Leamington Spa",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pub",
        Address: "4 Clarendon Ave",
    },
    {
        Name: "McDonald's Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "3.8",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "34 THE PARADE",
    },
    {
        Name: "House Leamington",
        Location: "Leamington Spa",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "130 Parade",
    },
    {
        Name: "Bella cafe & bistro",
        Location: "Leamington Spa",
        Ratings: "4.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Cafe",
        Address: "17 Parade",
    },
    {
        Name: "The New Inn",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pub",
        Address: "195-197 Leam Terrace",
    },
    {
        Name: "Gb FishBar",
        Location: "Leamington Spa",
        Ratings: "3.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Takeaway",
        Address: "54 Tavistock St",
    },
    {
        Name: "Casa Rica",
        Location: "Leamington Spa",
        Ratings: "3.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Mexican",
        Address: "1 Brunswick St",
    },
    {
        Name: "Red Fort",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Indian Takeaway",
        Address: "46 High St",
    },
    {
        Name: "The RiverCross Warwick",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Modern Indian",
        Address: "204 Emscote Rd",
    },
    {
        Name: "Johnny's Place",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Fish & Chips",
        Address: "8 Shopping Centre Whitnash Estate",
    },
    {
        Name: "The Cricketers",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "19 Archery Rd",
    },
    {
        Name: "BeerTorrent",
        Location: "Leamington Spa",
        Ratings: "4.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Brewing Supply Shop",
        Address: "25A Clemens St",
    },
    {
        Name: "The Urban Fox",
        Location: "Leamington Spa",
        Ratings: "4.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Pub",
        Address: "163 Tachbrook Rd",
    },
    {
        Name: "New Blossom Court",
        Location: "Leamington Spa",
        Ratings: "4.8",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "42 Rugby Rd",
    },
    {
        Name: "The Dining Room at Mallory Court Hotel & Spa",
        Location: "Leamington Spa",
        Ratings: "5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Harbury Ln",
    },
    {
        Name: "The Holly Bush Pub",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "2-4 Holly St",
    },
    {
        Name: "Balti Chef",
        Location: "Leamington Spa",
        Ratings: "4",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Indian Takeaway",
        Address: "31 Crown Way",
    },
    {
        Name: "New Kitchen",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "9 Market Corner",
    },
    {
        Name: "Chozen Noodle",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "154-156 Parade",
    },
    {
        Name: "Creams Caf√©",
        Location: "Leamington Spa",
        Ratings: "3.7",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "16 Livery St In Regent Court Shopping Centre",
    },
    {
        Name: "Tim's chipper",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Fish & Chips",
        Address: "10 market corner,Tachbrook street",
    },
    {
        Name: "Lina's place",
        Location: "Leamington Spa",
        Ratings: "5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Portuguese",
        Address: "Station Approach",
    },
    {
        Name: "Sealand Fish Bar Ltd",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Fish & Chips",
        Address: "168 Rugby Rd",
    },
    {
        Name: "Tortilla Pizza",
        Location: "Leamington Spa",
        Ratings: "4",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Takeaway",
        Address: "1 Brunswick St",
    },
    {
        Name: "Savi's Cafe",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Cafe",
        Address: "122 Regent St In Regent Court Shopping Centre",
    },
    {
        Name: "The Stag at Offchurch",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "British",
        Address: "Welsh Rd",
    },
    {
        Name: "The White Lion",
        Location: "Leamington Spa",
        Ratings: "4",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "60 Southam Rd",
    },
    {
        Name: "The Plough & Harrow",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Whitnash Rd",
    },
    {
        Name: "Subway",
        Location: "Leamington Spa",
        Ratings: "4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Sandwich",
        Address: "Ground Floor, Shop & Basement, 14 Victoria Terrace",
    },
    {
        Name: "Lansdowne Fish Bar",
        Location: "Leamington Spa",
        Ratings: "3.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Fish & Chips",
        Address: "8 Lansdowne St",
    },
    {
        Name: "Balti Vhujon",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Indian Takeaway",
        Address: "50A Queen St",
    },
    {
        Name: "The Steamhouse",
        Location: "Leamington Spa",
        Ratings: "4.8",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Bagel Shop",
        Address: "68 Warwick St",
    },
    {
        Name: "Pete's Plaice",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Fish & Chips",
        Address: "2 Stanleys Ct",
    },
    {
        Name: "Bang Bang Burrito",
        Location: "Leamington Spa",
        Ratings: "",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "22-24 Livery St In Regent Court Shopping Centre",
    },
    {
        Name: "Sabores De Portugal",
        Location: "Leamington Spa",
        Ratings: "5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "25B Clemens St",
    },
    {
        Name: "Spa Town Coffee",
        Location: "Leamington Spa",
        Ratings: "4.8",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Coffee Shop",
        Address: "94 Warwick St",
    },
    {
        Name: "Greggs",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Bakery",
        Address: "19/21 The Parade",
    },
    {
        Name: "Bunters Snack Shop",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Sandwich",
        Address: "1 Church Walk",
    },
    {
        Name: "The Heathcote",
        Location: "Leamington Spa",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Tachbrook Road",
    },
    {
        Name: "Seafare Fish Bar",
        Location: "Leamington Spa",
        Ratings: "",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Seafood",
        Address: "2 Stanleys Ct",
    },
    {
        Name: "The Orient",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "174A Emscote Rd",
    },
    {
        Name: "The Brasserie at Mallory Court",
        Location: "Leamington Spa",
        Ratings: "",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Harbury Ln",
    },
    {
        Name: "Hong Kong House",
        Location: "Leamington Spa",
        Ratings: "3.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "13 Clemens St",
    },
    {
        Name: "Sydenham Balti & Pizza",
        Location: "Leamington Spa",
        Ratings: "3.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Indian Takeaway",
        Address: "7 Stanleys Ct",
    },
    {
        Name: "Newbold Comyn Arms",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pub",
        Address: "Newbold Terrace E",
    },
    {
        Name: "The Waterside Inn",
        Location: "Leamington Spa",
        Ratings: "3.8",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Queensway",
    },
    {
        Name: "TJ's Bar & Grill",
        Location: "Leamington Spa",
        Ratings: "3.4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Grill",
        Address: "45-47 Bath St",
    },
    {
        Name: "Le Cafe",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Cafe",
        Address: "135 Regent St",
    },
    {
        Name: "The Aviary Cafe",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Cafe",
        Address: "",
    },
    {
        Name: "Flames Pizza And Grill",
        Location: "Leamington Spa",
        Ratings: "3.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Pizza Delivery",
        Address: "48 High St",
    },
    {
        Name: "Tuscano's Pizza",
        Location: "Leamington Spa",
        Ratings: "3",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Pizza Takeaway",
        Address: "25 Bath St",
    },
    {
        Name: "Winson Takeaway",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "71 Crown Way",
    },
    {
        Name: "The Neighbourhood",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "44 Warwick St",
    },
    {
        Name: "The Oxford Cafe",
        Location: "Leamington Spa",
        Ratings: "4.7",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Cafe",
        Address: "21 Clarendon Ave",
    },
    {
        Name: "Tari's Kitchen & Barfia Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Confectionery",
        Address: "6 The Shopping Centre",
    },
    {
        Name: "Robbie's Restaurant",
        Location: "Leamington Spa",
        Ratings: "4.8",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "74 Smith St",
    },
    {
        Name: "Murphy's Bar",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Pub",
        Address: "33 Regent St",
    },
    {
        Name: "Millenium Sweethouse",
        Location: "Leamington Spa",
        Ratings: "3.5",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "16 High St",
    },
    {
        Name: "Maya's Indian Brasserie",
        Location: "Leamington Spa",
        Ratings: "4.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Warwick",
    },
    {
        Name: "Heathcote Inn",
        Location: "Leamington Spa",
        Ratings: "5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Family Friendly",
        Address: "Leamington Spa",
    },
    {
        Name: "Golden Fish Bar & Kebab",
        Location: "Leamington Spa",
        Ratings: "2.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Takeaway",
        Address: "Leamington Spa",
    },
    {
        Name: "Pizza Hut Delivery",
        Location: "Leamington Spa",
        Ratings: "2.9",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pizza",
        Address: "Leamington Spa",
    },
    {
        Name: "Bar Angeli",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Coffee Shop",
        Address: "Leamington Spa In Regent Court Shopping Centre",
    },
    {
        Name: "Warwick Spice Indian and Bangladeshi Restaurant",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "Warwick",
    },
    {
        Name: "Papa John's Pizza",
        Location: "Leamington Spa",
        Ratings: "3.1",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pizza",
        Address: "Leamington Spa",
    },
    {
        Name: "Giggling Squid Warwick",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Thai",
        Address: "Warwick Near Warwick Castle",
    },
    {
        Name: "The Art Kitchen",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Thai",
        Address: "Warwick",
    },
    {
        Name: "Fat Birds Cafe",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Cafe",
        Address: "Leamington Spa",
    },
    {
        Name: "VILLA's Deli - Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Leamington Spa",
    },
    {
        Name: "The Good Food House",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "",
    },
    {
        Name: "Micatto",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Italian",
        Address: "Warwick",
    },
    {
        Name: "Totally Thai",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Thai",
        Address: "Warwick",
    },
    {
        Name: "bebas modern greek",
        Location: "Leamington Spa",
        Ratings: "4.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Cafe",
        Address: "Leamington Spa",
    },
    {
        Name: "Vivaanta Indian Restaurant & Bar",
        Location: "Leamington Spa",
        Ratings: "4.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Warwick",
    },
    {
        Name: "Tasca Dali",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Spanish",
        Address: "Warwick Near Warwick Castle",
    },
    {
        Name: "Swirls",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Ice Cream",
        Address: "Leamington Spa",
    },
    {
        Name: "White Hart",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Leamington Spa",
    },
    {
        Name: "The Leopard Inn - Bishops Tachbrook",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Leamington Spa",
    },
    {
        Name: "The Counting House",
        Location: "Leamington Spa",
        Ratings: "5",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "Leamington Spa",
    },
    {
        Name: "Il Piccolinos Ltd",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Italian",
        Address: "Warwick",
    },
    {
        Name: "Royal Horse",
        Location: "Leamington Spa",
        Ratings: "4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Pub",
        Address: "",
    },
    {
        Name: "Dough & Brew",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pizza",
        Address: "Warwick In Westgate House",
    },
    {
        Name: "Jambavan",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "Warwick Near Warwick Castle",
    },
    {
        Name: "The Muse Coffee House",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Cafe",
        Address: "Leamington Spa In Regent Court Shopping Centre",
    },
    {
        Name: "Pastelaria Portuguesa",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Warwick",
    },
    {
        Name: "Saffron Gold",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "Warwick In Westgate House",
    },
    {
        Name: "Haunted",
        Location: "Leamington Spa",
        Ratings: "4.7",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "",
    },
    {
        Name: "Greggs",
        Location: "Leamington Spa",
        Ratings: "4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Bakery",
        Address: "In Leamington Shopping Park",
    },
    {
        Name: "The Saxon Mill",
        Location: "Leamington Spa",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Gastropub",
        Address: "Warwick",
    },
    {
        Name: "Subway",
        Location: "Leamington Spa",
        Ratings: "3.1",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Sandwich",
        Address: "Leamington Spa",
    },
    {
        Name: "Pizza Express",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pizza",
        Address: "Warwick",
    },
    {
        Name: "The Crown Inn Harbury",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pub",
        Address: "Leamington Spa",
    },
    {
        Name: "Angelica's Tea & Cake",
        Location: "Leamington Spa",
        Ratings: "3.8",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Tea Room",
        Address: "Leamington Spa",
    },
    {
        Name: "Sicilian Pizza",
        Location: "Leamington Spa",
        Ratings: "2.8",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Kebab Shop",
        Address: "Leamington Spa",
    },
    {
        Name: "The Red Lion Hunningham",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Gastropub",
        Address: "Leamington Spa",
    },
    {
        Name: "Jephson Gardens",
        Location: "Leamington Spa",
        Ratings: "4.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Park and Garden",
        Address: "Leamington Spa",
    },
    {
        Name: "The Roebuck Inn",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Warwick",
    },
    {
        Name: "WBS cafe",
        Location: "Campus",
        Ratings: "5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "University of Warwick, Stoneleigh Warwick Business School,Ground Floor, Scarman Rd",
    },
    {
        Name: "Canopy",
        Location: "Campus",
        Ratings: "",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "6 Lord Bhattacharyya Way",
    },
    {
        Name: "Pret a Manger",
        Location: "Campus",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Sandwich",
        Address: "Rootes Social Building",
    },
    {
        Name: "Terrace Bar",
        Location: "Campus",
        Ratings: "4.3",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "University Of Warwick, Gibbet Hill Rd",
    },
    {
        Name: "The Dirty Duck",
        Location: "Campus",
        Ratings: "4.1",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Pub",
        Address: "Gibbet Hill Rd",
    },
    {
        Name: "Basecamp University of Warwick",
        Location: "Campus",
        Ratings: "5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Fast Food",
        Address: "University of Warwick",
    },
    {
        Name: "Curiositea",
        Location: "Campus",
        Ratings: "4.3",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Tea Room",
        Address: "Gibbet Hill Rd",
    },
    {
        Name: "Varsity",
        Location: "Campus",
        Ratings: "3.8",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Gibbet Hill Rd",
    },
    {
        Name: "Ristorante Da Vinci",
        Location: "Campus",
        Ratings: "4.6",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Italian",
        Address: "50 Earlsdon St",
    },
    {
        Name: "The Bread Oven",
        Location: "Campus",
        Ratings: "4.5",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Bakery",
        Address: "Gibbet Hill Rd",
    },
    {
        Name: "The Phantom Coach",
        Location: "Campus",
        Ratings: "4.1",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Grill",
        Address: "Fletchamstead Hwy",
    },
    {
        Name: "Harringtons On The Hill",
        Location: "Campus",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "42 Castle Hill",
    },
    {
        Name: "Bar Fusion",
        Location: "Campus",
        Ratings: "2.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "University of Warwick",
    },
    {
        Name: "Taste Vietnam",
        Location: "Coventry",
        Ratings: "4.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Vietnamese",
        Address: "188 Spon St",
    },
    {
        Name: "The Botanist Coventry",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Unit 4, Cathedral Lanes Shopping Centre, Broadgate",
    },
    {
        Name: "Ristorante Da Vinci",
        Location: "Coventry",
        Ratings: "4.6",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Italian",
        Address: "50 Earlsdon St",
    },
    {
        Name: "Selminas Restaurant & Bar",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Mexican",
        Address: "16 Spon St",
    },
    {
        Name: "Restaurant du Liban",
        Location: "Coventry",
        Ratings: "4.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "39 Smithford Way",
    },
    {
        Name: "Simmer Down Restaurant",
        Location: "Coventry",
        Ratings: "4.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "r, o, 74 Walsgrave Rd",
    },
    {
        Name: "Sovrano Caffe Coventry",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Italian",
        Address: "8 Smithford Way",
    },
    {
        Name: "Earlsdon Mega Fish Bar",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Persian",
        Address: "72-74 Albany Rd",
    },
    {
        Name: "Cosy Club",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "British",
        Address: "Cathedral Lanes Shopping Centre",
    },
    {
        Name: "Falafel Corner Uk",
        Location: "Coventry",
        Ratings: "4.8",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Falafel",
        Address: "1NE, 32 Broadgate",
    },
    {
        Name: "The Artisan bar and grill",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Grill",
        Address: "Ryley St",
    },
    {
        Name: "GOURMET FOOD KITCHEN",
        Location: "Coventry",
        Ratings: "4.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Unit 2H Fargo Village, Far Gosford St",
    },
    {
        Name: "Antalya Restaurant",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Turkish",
        Address: "124 Walsgrave Rd",
    },
    {
        Name: "Nashaa",
        Location: "Coventry",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "154 Longford Rd",
    },
    {
        Name: "Arco Restaurant",
        Location: "Coventry",
        Ratings: "4.8",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Italian",
        Address: "13 Butts",
    },
    {
        Name: "The Ocean",
        Location: "Coventry",
        Ratings: "4.7",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "46 Jubilee Cres",
    },
    {
        Name: "Al-Bader Restaurant in Coventry City",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Lebanese",
        Address: "31 High St",
    },
    {
        Name: "Aqua Food & Mood",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Lebanese",
        Address: "14a Butts",
    },
    {
        Name: "Playwrights",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Grill",
        Address: "4-6 Hay Ln",
    },
    {
        Name: "Noodle Bar",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Chinese",
        Address: "9 Bull Yard",
    },
    {
        Name: "The Millpool",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "English",
        Address: "Hipswell Hwy",
    },
    {
        Name: "Basement Browns",
        Location: "Coventry",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pizza",
        Address: "14 High St",
    },
    {
        Name: "Blue Orchid",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Thai",
        Address: "14 Butts",
    },
    {
        Name: "Leave It To Esmie",
        Location: "Coventry",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Caribbean",
        Address: "13a Fargo Village, Far Gosford St",
    },
    {
        Name: "Jinseon Korean BBQ Restaurant & Bar",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Korean BBQ",
        Address: "Unit 5 Priory St, Fairfax St",
    },
    {
        Name: "Bayley Lane Kitchen & Cocktails",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "24 Bayley Ln",
    },
    {
        Name: "Cafe Italia",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "9-11 Trinity St",
    },
    {
        Name: "A Sushi Japanese and Korean Restaurant",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Japanese",
        Address: "43 Hertford St",
    },
    {
        Name: "Habibi",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Middle Eastern",
        Address: "142 Far Gosford St",
    },
    {
        Name: "SHIN Japanese Kitchen & Bar",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Authentic Japanese",
        Address: "2, Priory Place, Fairfax St",
    },
    {
        Name: "The Farmhouse",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "215 Beechwood Ave",
    },
    {
        Name: "My Dhabba",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "1-3 Lower Holyhead Rd",
    },
    {
        Name: "Bombay Joes",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "277 Walsgrave Rd",
    },
    {
        Name: "Turmeric Gold .award winning Indian restaurant",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "166 Spon St",
    },
    {
        Name: "Queens Road Mediterranean Kitchen",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Modern European",
        Address: "The Butts",
    },
    {
        Name: "De Laban",
        Location: "Coventry",
        Ratings: "4.7",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "82 Dawlish Dr",
    },
    {
        Name: "Sultan Restaurant",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Turkish",
        Address: "94-95 Far Gosford St",
    },
    {
        Name: "Street",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "24-26 Earlsdon St",
    },
    {
        Name: "Thai Dusit",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Thai",
        Address: "39 London Rd",
    },
    {
        Name: "Shimla Spice",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Bangladeshi",
        Address: "3 Butts",
    },
    {
        Name: "Nando's Coventry - City",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Chicken",
        Address: "1-6, Ribbon Factory, Trinity St",
    },
    {
        Name: "Zorbas",
        Location: "Coventry",
        Ratings: "4.6",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Greek",
        Address: "87-89 Radford Rd",
    },
    {
        Name: "Pickles Indian And Grill Cuisine",
        Location: "Coventry",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "51, 52 Spon End",
    },
    {
        Name: "The Lazy Monkey Bar & Kitchen",
        Location: "Coventry",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "26 Warwick Row",
    },
    {
        Name: "Franzos Coventry",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Halal",
        Address: "14 Corporation St",
    },
    {
        Name: "Coundon Indian Cuisine",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "61 Barker's Butts Ln",
    },
    {
        Name: "Oodles N'Oodles",
        Location: "Coventry",
        Ratings: "3.9",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Unit 1A, Vanguard Ave",
    },
    {
        Name: "Zizzi",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Cathedral Lanes Shopping Centre, Broadgate",
    },
    {
        Name: "3 Five 1 Restaurant",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pakistani",
        Address: "351 Stoney Stanton Rd",
    },
    {
        Name: "Rupali Restaurant",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "335, 337 Tile Hill Ln",
    },
    {
        Name: "MOD Pizza - Coventry",
        Location: "Coventry",
        Ratings: "4.6",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Pizza",
        Address: "Unit 3 Cathedral Lanes Shopping Centre, Broadgate",
    },
    {
        Name: "Delhi 76 indian restaurant",
        Location: "Coventry",
        Ratings: "4.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "76 Albany Rd",
    },
    {
        Name: "Las Iguanas Coventry",
        Location: "Coventry",
        Ratings: "4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Latin American",
        Address: "4 Cathedral Lanes Shopping Centre, Broadgate",
    },
    {
        Name: "Akbars",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "7-9, The Butts, Queen's Rd",
    },
    {
        Name: "China Red Chinese Restaurant & Karaoke Bar",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Chinese",
        Address: "58 Hertford St",
    },
    {
        Name: "Restaurant Cracovianca",
        Location: "Coventry",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Polish",
        Address: "Polish Community Centre, Springfield Rd",
    },
    {
        Name: "Asiana Restaurant",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "311 Holbrook Ln",
    },
    {
        Name: "wagamama",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "cathedral lanes shopping centre",
    },
    {
        Name: "The Old Crown",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "466 Aldermans Green Rd",
    },
    {
        Name: "Slovianka Polish & Ukrainian cuisine",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "83 Far Gosford St",
    },
    {
        Name: "Arabian Bites",
        Location: "Coventry",
        Ratings: "4.8",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "1a Lamb St",
    },
    {
        Name: "Golden Cross",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pub",
        Address: "8 Hay Ln",
    },
    {
        Name: "Bistrot Pierre",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "French",
        Address: "Cathedral Lanes Shopping Centre, Broadgate",
    },
    {
        Name: "Masala Jack's",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "170 Holbrook Ln",
    },
    {
        Name: "Taksim Restaurant",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "243 Stoney Stanton Rd",
    },
    {
        Name: "Miller & Carter Coventry",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Steak",
        Address: "Kenpas Hwy",
    },
    {
        Name: "The Lion's Inn",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Modern Indian",
        Address: "Barker's Butts Ln",
    },
    {
        Name: "Ginger Orange",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "50 Kenpas Hwy",
    },
    {
        Name: "The Mulberry - Coventry",
        Location: "Coventry",
        Ratings: "4.8",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Gastropub",
        Address: "Belgrade Plaza, Upper Well St",
    },
    {
        Name: "Bombay Palace",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "64 Earlsdon St",
    },
    {
        Name: "Steakout Coventry",
        Location: "Coventry",
        Ratings: "4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Steak",
        Address: "6 Corporation St",
    },
    {
        Name: "Taksim Turkish Restaurant",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Ahmed News, 245 Stoney Stanton Rd",
    },
    {
        Name: "Millsy's Cafe Bar",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Bistro",
        Address: "20 Earlsdon St",
    },
    {
        Name: "Allys Balti House",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "48 Earlsdon St",
    },
    {
        Name: "Royal Cha",
        Location: "Coventry",
        Ratings: "4.7",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Sushi",
        Address: "21A Cross Cheaping",
    },
    {
        Name: "Chi Bar",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "13 High St",
    },
    {
        Name: "TGI Fridays - Coventry",
        Location: "Coventry",
        Ratings: "3.8",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "American",
        Address: "Rugby Rd",
    },
    {
        Name: "Pamir Restaurant",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "603-607 Stoney Stanton Rd",
    },
    {
        Name: "Rodizio Rico",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "16-18 Corporation St",
    },
    {
        Name: "German Doner Kebab",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "5-9 The Burges",
    },
    {
        Name: "Pasha Turkish Grill",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Turkish",
        Address: "136 Far Gosford St",
    },
    {
        Name: "Wing Wah Chinese Restaurant",
        Location: "Coventry",
        Ratings: "3.8",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Chinese",
        Address: "583 Fletchamstead Hwy",
    },
    {
        Name: "Hadramud Mindi Restaurant",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "120 Lower Ford St",
    },
    {
        Name: "The Dhaka Dynasty",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "£££",
        Category: "Restaurant",
        Cuisine: "Bangladeshi",
        Address: "292-294, Walsgrave Rd",
    },
    {
        Name: "Harvester Skydome Coventry",
        Location: "Coventry",
        Ratings: "4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Grill",
        Address: "Skydome, The, Croft Rd",
    },
    {
        Name: "Palms",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "West African",
        Address: "116 Far Gosford St",
    },
    {
        Name: "Pizza Express",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Pizza",
        Address: "Units 3-4, Belgrade Plaza",
    },
    {
        Name: "Cathedral Lanes Centre",
        Location: "Coventry",
        Ratings: "3.8",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Broadgate",
    },
    {
        Name: "Khushi Grill",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "88 Wheelwright Ln",
    },
    {
        Name: "Sky Lounge Shisha Coventry",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Sky Lounge Rooftop, The Old FIre Station, Hales Street",
    },
    {
        Name: "Bella Italia - Coventry",
        Location: "Coventry",
        Ratings: "3.8",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Italian",
        Address: "Unit 1 4, Belgrade Plaza, Upper Well St",
    },
    {
        Name: "Zeenat",
        Location: "Coventry",
        Ratings: "3.9",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Afghani",
        Address: "159 Cambridge St",
    },
    {
        Name: "Dunya",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Turkish",
        Address: "8 Victoria St",
    },
    {
        Name: "Angelito Cafe & Tapas Bar Coventry",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Tapas",
        Address: "54 Earlsdon St",
    },
    {
        Name: "To Pho",
        Location: "Coventry",
        Ratings: "4.8",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Vietnamese",
        Address: "Unit R, 1 Priory St, Fairfax St",
    },
    {
        Name: "Panjabi Hut",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Vegetarian",
        Address: "176, 178 Clay Ln",
    },
    {
        Name: "Istanbul Restaurant",
        Location: "Coventry",
        Ratings: "3.9",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "17 Butts",
    },
    {
        Name: "Standard Sweet Centre & Restaurant",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "424-426 Foleshill Rd",
    },
    {
        Name: "The Walsgrave Stonehouse",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Carvery",
        Address: "382 Walsgrave Rd",
    },
    {
        Name: "The Red Lion",
        Location: "Coventry",
        Ratings: "3.9",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "British",
        Address: "Ansty Rd",
    },
    {
        Name: "SultanKebabCoventry",
        Location: "Coventry",
        Ratings: "4",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Kebab Shop",
        Address: "13 Hales St",
    },
    {
        Name: "Char-Grill",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Fast Food",
        Address: "67 Far Gosford St",
    },
    {
        Name: "Al Rahman Indian",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "223 Torrington Ave",
    },
    {
        Name: "Foleshill Grill",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Fast Food",
        Address: "360 Foleshill Grill",
    },
    {
        Name: "The Courtyard Restaurant",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Priory St",
    },
    {
        Name: "Finger Licking Kitchen & Jerk Centre",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Caribbean",
        Address: "8 Lower Ford St",
    },
    {
        Name: "Hana Moon Japanese Restaurant",
        Location: "Coventry",
        Ratings: "3.8",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Japanese",
        Address: "4 Hales St",
    },
    {
        Name: "Aldermans Green Fish Bar",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Fish & Chips",
        Address: "234 Alderman's Green Rd",
    },
    {
        Name: "Pepe's Piri Piri",
        Location: "Coventry",
        Ratings: "4.3",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Fast Food",
        Address: "23 Trinity St",
    },
    {
        Name: "Cafe Morso Coventry",
        Location: "Coventry",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "16a The Cooperative, Corporation St",
    },
    {
        Name: "The White Lion",
        Location: "Coventry",
        Ratings: "4",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Hawkes Mill Ln",
    },
    {
        Name: "Cinnamons at Beechwood",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Sandpits Ln",
    },
    {
        Name: "McDonald's Coventry - West Orchards",
        Location: "Coventry",
        Ratings: "3.9",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Fast Food",
        Address: "Smithford Way West Orchards Shopping Centre",
    },
    {
        Name: "Majors Bar & Restaurant",
        Location: "Coventry",
        Ratings: "5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Grill",
        Address: "140 Far Gosford St",
    },
    {
        Name: "PIZZA OMORE Coventry",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Fast Food",
        Address: "6 Hales St",
    },
    {
        Name: "Dosa Village",
        Location: "Coventry",
        Ratings: "3.8",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "72 Barker's Butts Ln",
    },
    {
        Name: "Evergreen",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "177 Allesley Old Rd",
    },
    {
        Name: "T4 (Tea For U) Coventry",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "60 Broadgate",
    },
    {
        Name: "Bagel Brunch",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Coffee Shop",
        Address: "37 Smithford Way",
    },
    {
        Name: "Wok U Like",
        Location: "Coventry",
        Ratings: "4.7",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "92 Dudley St",
    },
    {
        Name: "Cinnamon Gold",
        Location: "Coventry",
        Ratings: "4.5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Indian Takeaway",
        Address: "112 Albany Rd",
    },
    {
        Name: "Curry Corner",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "15 Centaur Rd",
    },
    {
        Name: "Marina Fish Bar",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Fish & Chips",
        Address: "6 St James Ln",
    },
    {
        Name: "Chopstix - Coventry",
        Location: "Coventry",
        Ratings: "2.3",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Chinese",
        Address: "Unit R6 West Orchards Shopping Centre",
    },
    {
        Name: "Tuscany Pizza",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Pizza Takeaway",
        Address: "309 Holbrook Ln",
    },
    {
        Name: "McDonald's",
        Location: "Coventry",
        Ratings: "3.8",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Fast Food",
        Address: "26 Cross Cheaping",
    },
    {
        Name: "Wok @ Star",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "71 Barker's Butts Ln",
    },
    {
        Name: "Binley Banqueting Suite",
        Location: "Coventry",
        Ratings: "3.9",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "50 Grange Ave",
    },
    {
        Name: "Roti Junction",
        Location: "Coventry",
        Ratings: "3.9",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Indian Takeaway",
        Address: "215 Stoney Stanton Rd",
    },
    {
        Name: "Caspian Pizza Coventry",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Pizza Takeaway",
        Address: "112 Far Gosford St",
    },
    {
        Name: "Coventry Kebab House",
        Location: "Coventry",
        Ratings: "3.9",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Kebab Shop",
        Address: "8 New Union St",
    },
    {
        Name: "Jade Garden",
        Location: "Coventry",
        Ratings: "3.8",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Fast Food",
        Address: "122 Clay Ln",
    },
    {
        Name: "Indiana",
        Location: "Coventry",
        Ratings: "3.4",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Indian",
        Address: "477 Beake Ave",
    },
    {
        Name: "MAMACITAS Coventry",
        Location: "Coventry",
        Ratings: "5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Takeaway",
        Address: "Within the Barras Green Social club 2",
    },
    {
        Name: "Only Food and Sauces Coventry",
        Location: "Coventry",
        Ratings: "3",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "141-143 Foleshill Rd",
    },
    {
        Name: "The Tollgate Stonehouse",
        Location: "Coventry",
        Ratings: "3.6",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Family Friendly",
        Address: "Holyhead Road",
    },
    {
        Name: "Old Clarence",
        Location: "Coventry",
        Ratings: "4",
        PriceRange: "£",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Earlsdon Ave N",
    },
    {
        Name: "Dragon House",
        Location: "Coventry",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Chinese Takeaway",
        Address: "117 Church Ln",
    },
    {
        Name: "The Open Arms",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "Daventry Rd",
    },
    {
        Name: "Carmine's Quo Vadis",
        Location: "Coventry",
        Ratings: "5",
        PriceRange: "",
        Category: "Restaurant",
        Cuisine: "Restaurant",
        Address: "72 Barker's Butts Ln",
    },
    {
        Name: "Jephson Gardens",
        Location: "Leamington Spa",
        Ratings: "4.7",
        Category: "Park and Garden",
        Address: "Newbold Terrace",
    },
    {
        Name: "Victoria Park, Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.5",
        Category: "Park and Garden",
        Address: "",
    },
    {
        Name: "Villiers Street Park",
        Location: "Leamington Spa",
        Ratings: "4.4",
        Category: "Park and Garden",
        Address: "Villiers St",
    },
    {
        Name: "Newbold Comyn Country Park",
        Location: "Leamington Spa",
        Ratings: "4.7",
        Category: "Park and Garden",
        Address: "",
    },
    {
        Name: "Christchurch Gardens",
        Location: "Leamington Spa",
        Ratings: "4.5",
        Category: "Park and Garden",
        Address: "",
    },
    {
        Name: "Newbold Comyn Skate Park",
        Location: "Leamington Spa",
        Ratings: "4.4",
        Category: "Park and Garden",
        Address: "Newbold Comyn",
    },
    {
        Name: "Cubbington Waterworks Play Area",
        Location: "Leamington Spa",
        Ratings: "4.4",
        Category: "Park and Garden",
        Address: "Cubbington Rd",
    },
    {
        Name: "Dragon Cottage Play Area",
        Location: "Leamington Spa",
        Ratings: "3.8",
        Category: "Park and Garden",
        Address: "Guys Cliffe Ave",
    },
    {
        Name: "Kennedy Square Park",
        Location: "Leamington Spa",
        Ratings: "3.8",
        Category: "Park and Garden",
        Address: "",
    },
    {
        Name: "Campion Hill Cycle Track",
        Location: "Leamington Spa",
        Ratings: "4.2",
        Category: "Park and Garden",
        Address: "90 Leicester St",
    },
    {
        Name: "Mason Avenue Park",
        Location: "Leamington Spa",
        Ratings: "4.2",
        Category: "Park and Garden",
        Address: "Mason Ave",
    },
    {
        Name: "Eagle Recreation Ground and Play Area",
        Location: "Leamington Spa",
        Ratings: "4",
        Category: "Park and Garden",
        Address: "Aylesford St",
    },
    {
        Name: "Jephson Memorial",
        Location: "Leamington Spa",
        Ratings: "4.6",
        Category: "Park and Garden",
        Address: "",
    },
    {
        Name: "The Holt Recreation Ground",
        Location: "Leamington Spa",
        Ratings: "4.2",
        Category: "Park and Garden",
        Address: "The Holt",
    },
    {
        Name: "Ford's Fields",
        Location: "Leamington Spa",
        Ratings: "3.8",
        Category: "Park and Garden",
        Address: "",
    },
    {
        Name: "Royal Pump Room Gardens",
        Location: "Leamington Spa",
        Ratings: "4.4",
        Category: "Park and Garden",
        Address: "",
    },
    {
        Name: "Radford Semele Recreation Ground",
        Location: "Leamington Spa",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "Lewis Rd",
    },
    {
        Name: "Washbourne Fields",
        Location: "Leamington Spa",
        Ratings: "4.6",
        Category: "Park and Garden",
        Address: "Washbourne Rd",
    },
    {
        Name: "St Nicholas' Park, Warwick",
        Location: "Leamington Spa",
        Ratings: "4.6",
        Category: "Park and Garden",
        Address: "",
    },
    {
        Name: "Myton Park",
        Location: "Leamington Spa",
        Ratings: "4.5",
        Category: "Park and Garden",
        Address: "10 Dodd Ave",
    },
    {
        Name: "War Memorial Park",
        Location: "Coventry",
        Ratings: "4.6",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Brandon Marsh Nature Reserve",
        Location: "Coventry",
        Ratings: "4.7",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Allesley Park",
        Location: "Coventry",
        Ratings: "4.5",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Caludon Castle Park",
        Location: "Coventry",
        Ratings: "4.2",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Spencer Park",
        Location: "Coventry",
        Ratings: "4.4",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Longford Park",
        Location: "Coventry",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Coundon Hall Park",
        Location: "Coventry",
        Ratings: "4.4",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Swanswell Park & Pool",
        Location: "Coventry",
        Ratings: "4",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Wyken Croft Nature Park",
        Location: "Coventry",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Coombe Country Park",
        Location: "Coventry",
        Ratings: "4.6",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Quinton Park",
        Location: "Coventry",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Gosford Park",
        Location: "Coventry",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Primrose Hill Park",
        Location: "Coventry",
        Ratings: "3.7",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Edgwick Park",
        Location: "Coventry",
        Ratings: "3.5",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Lake View Park",
        Location: "Coventry",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Coundon Wedge",
        Location: "Coventry",
        Ratings: "4.6",
        Category: "Park and Garden",
        Address: "",
    },
    {
        Name: "Holbrooks Park",
        Location: "Coventry",
        Ratings: "3.9",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Tocil Wood and Nature Reserve",
        Location: "Coventry",
        Ratings: "4.5",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Charterhouse Field",
        Location: "Coventry",
        Ratings: "4.6",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Public Park",
        Location: "Coventry",
        Ratings: "3.4",
        Category: "Park and Garden",
        Address: "Coventry",
    },
    {
        Name: "Stoke Heath",
        Location: "Coventry",
        Ratings: "4.1",
        Category: "Park and Garden",
        Address: "Heath Cres",
    },
    {
        Name: "Longford Park",
        Location: "Coventry",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "Longford Rd",
    },
    {
        Name: "Keresley Jubilee Wood",
        Location: "Coventry",
        Ratings: "4.5",
        Category: "Park and Garden",
        Address: "111 Watery Ln",
    },
    {
        Name: "Pinley Gardens",
        Location: "Coventry",
        Ratings: "4",
        Category: "Park and Garden",
        Address: "88 The Moorfield",
    },
    {
        Name: "Allesley Park Walled Garden",
        Location: "Coventry",
        Ratings: "4.5",
        Category: "Park and Garden",
        Address: "73 Buckhold Dr",
    },
    {
        Name: "Top Green",
        Location: "Coventry",
        Ratings: "4.4",
        Category: "Park and Garden",
        Address: "Warwick Rd",
    },
    {
        Name: "Wyken Slough",
        Location: "Coventry",
        Ratings: "4.2",
        Category: "Park and Garden",
        Address: "268 Alderman's Green Rd",
    },
    {
        Name: "Signet Square Children's Park",
        Location: "Coventry",
        Ratings: "4",
        Category: "Park and Garden",
        Address: "94 Signet Square",
    },
    {
        Name: "Gosford Green",
        Location: "Coventry",
        Ratings: "4.2",
        Category: "Park and Garden",
        Address: "Binley Rd",
    },
    {
        Name: "Radford Recreation Ground",
        Location: "Coventry",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "Swillington Rd",
    },
    {
        Name: "Greyfriars Green",
        Location: "Coventry",
        Ratings: "4.4",
        Category: "Park and Garden",
        Address: "3 Queen Victoria Rd",
    },
    {
        Name: "Cycle Speedway Circuit Coventry",
        Location: "Coventry",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "96 Hearsall Ln",
    },
    {
        Name: "Brandon Wood",
        Location: "Coventry",
        Ratings: "4.5",
        Category: "Park and Garden",
        Address: "Unnamed Road",
    },
    {
        Name: "All Saints Square",
        Location: "Coventry",
        Ratings: "3",
        Category: "Park and Garden",
        Address: "43-51 Far Gosford St",
    },
    {
        Name: "Ryton Pools Country Park",
        Location: "Coventry",
        Ratings: "4.6",
        Category: "Park and Garden",
        Address: "Ryton Rd",
    },
    {
        Name: "Crampers Field",
        Location: "Coventry",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "",
    },
    {
        Name: "Ponderosa Field",
        Location: "Coventry",
        Ratings: "3.7",
        Category: "Park and Garden",
        Address: "Dunhill Ave",
    },
    {
        Name: "Stoke Green",
        Location: "Coventry",
        Ratings: "4.4",
        Category: "Park and Garden",
        Address: "Stoke Green",
    },
    {
        Name: "Longford Community Nature Park",
        Location: "Coventry",
        Ratings: "4.5",
        Category: "Park and Garden",
        Address: "6ED, Longford Rd",
    },
    {
        Name: "Allesley Park Fairy Garden",
        Location: "Coventry",
        Ratings: "4.4",
        Category: "Park and Garden",
        Address: "Unnamed Road",
    },
    {
        Name: "Moat House",
        Location: "Coventry",
        Ratings: "4.4",
        Category: "Park and Garden",
        Address: "Henley Rd",
    },
    {
        Name: "Millisons Wood",
        Location: "Coventry",
        Ratings: "4.6",
        Category: "Park and Garden",
        Address: "Albert Rd",
    },
    {
        Name: "Brandon Marsh Nature Reserve",
        Location: "Coventry",
        Ratings: "4.6",
        Category: "Park and Garden",
        Address: "Brandon Marsh Nature Centre, Brandon Lane",
    },
    {
        Name: "Lady Herbert's Gardens",
        Location: "Coventry",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "",
    },
    {
        Name: "Jardine Crescent Play Area",
        Location: "Coventry",
        Ratings: "3.3",
        Category: "Park and Garden",
        Address: "Jardine Cres",
    },
    {
        Name: "Abbey Fields",
        Location: "Coventry",
        Ratings: "4.6",
        Category: "Park and Garden",
        Address: "Bridge St",
    },
    {
        Name: "Canley Ford",
        Location: "Coventry",
        Ratings: "4.5",
        Category: "Park and Garden",
        Address: "52 Fletchamstead Hwy",
    },
    {
        Name: "Fillongley Park",
        Location: "Coventry",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "10 Church Ln",
    },
    {
        Name: "Sowe Valley",
        Location: "Coventry",
        Ratings: "4.3",
        Category: "Park and Garden",
        Address: "Off Allard Way",
    },
    {
        Name: "Hearsall Common",
        Location: "Coventry",
        Ratings: "4.1",
        Category: "Park and Garden",
        Address: "Hearsall Ln",
    },
    {
        Name: "Coombe pool",
        Location: "Coventry",
        Ratings: "3.3",
        Category: "Park and Garden",
        Address: "Coombe Abbey, Brinklow Rd",
    },
    {
        Name: "Ena Road Play Area",
        Location: "Coventry",
        Ratings: "3.5",
        Category: "Park and Garden",
        Address: "Ena Rd",
    },
    {
        Name: "Daffern's Wood Local Nature Reserve",
        Location: "Coventry",
        Ratings: "4.8",
        Category: "Park and Garden",
        Address: "Morgan Cl",
    },
    {
        Name: "Brandon Reach Nature Reserve",
        Location: "Coventry",
        Ratings: "4.9",
        Category: "Park and Garden",
        Address: "",
    },
    {
        Name: "Dyer's Lane Recreation Ground",
        Location: "Coventry",
        Ratings: "4.6",
        Category: "Park and Garden",
        Address: "Dyer's Ln",
    },
    {
        Name: "Coventry City Centre Car Park",
        Location: "Coventry",
        Ratings: "4.2",
        Category: "Park and Garden",
        Address: "915 Corporation St",
    },
    {
        Name: "The Wave",
        Location: "Coventry",
        Ratings: "4",
        Category: "Park and Garden",
        Address: "New Union St",
    },
    {
        Name: "Altoria Nightclub",
        Location: "Leamington Spa",
        Ratings: "2.8",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "45 Warwick St",
    },
    {
        Name: "Smack",
        Location: "Leamington Spa",
        Ratings: "2.6",
        PriceRange: "£",
        Category: "Bar / Nightclub",
        Address: "5-9 Tavistock St",
    },
    {
        Name: "Neon",
        Location: "Leamington Spa",
        Ratings: "2.8",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "2 Spencer St",
    },
    {
        Name: "Shades Gentlemans Club",
        Location: "Leamington Spa",
        Ratings: "3.2",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "6A High St",
    },
    {
        Name: "Clink Cellar Bar",
        Location: "Leamington Spa",
        Ratings: "3.9",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "40 Warwick St",
    },
    {
        Name: "Moo",
        Location: "Leamington Spa",
        Ratings: "3.7",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "24 Russell St",
    },
    {
        Name: "Discoteca",
        Location: "Leamington Spa",
        Ratings: "",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "",
    },
    {
        Name: "Assembly Leamington",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "The Assembly, Spencer St",
    },
    {
        Name: "Habanaloko salsa dance holidays",
        Location: "Leamington Spa",
        Ratings: "",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "18A Clarendon St",
    },
    {
        Name: "Kelsey's Live",
        Location: "Leamington Spa",
        Ratings: "3.6",
        PriceRange: "£",
        Category: "Bar / Nightclub",
        Address: "15 High St",
    },
    {
        Name: "Jephsons Bar",
        Location: "Leamington Spa",
        Ratings: "4",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "50 Tavistock St",
    },
    {
        Name: "Cellar Club",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "£££",
        Category: "Bar / Nightclub",
        Address: "7 Parade",
    },
    {
        Name: "Fizzy Moon Limited",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "35 Regent St",
    },
    {
        Name: "Copper Pot",
        Location: "Leamington Spa",
        Ratings: "4",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "41-43 Warwick St",
    },
    {
        Name: "Cliff Cleaver Pavilion",
        Location: "Leamington Spa",
        Ratings: "",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "Off Stonehouse Cl",
    },
    {
        Name: "House Leamington",
        Location: "Leamington Spa",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "130 Parade",
    },
    {
        Name: "The White Horse",
        Location: "Leamington Spa",
        Ratings: "4.2",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "4 Clarendon Ave",
    },
    {
        Name: "The Clarendon Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "44-46 Clarendon Ave",
    },
    {
        Name: "The Somerville Arms",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "£",
        Category: "Bar / Nightclub",
        Address: "4 Campion Terrace",
    },
    {
        Name: "Bedford Street Bar",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "4 Bedford St",
    },
    {
        Name: "Justin Club",
        Location: "Leamington Spa",
        Ratings: "2",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "91 Upper Holly Walk",
    },
    {
        Name: "Prince Of Wales Inn",
        Location: "Leamington Spa",
        Ratings: "2.8",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "116 Warwick St",
    },
    {
        Name: "The Star & Garter",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "4-6 Warwick St",
    },
    {
        Name: "The Boiler Room",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "The Boiler Room, 6 Victoria Terrace",
    },
    {
        Name: "Woodland Tavern",
        Location: "Leamington Spa",
        Ratings: "4.4",
        PriceRange: "£",
        Category: "Bar / Nightclub",
        Address: "3 Regent St",
    },
    {
        Name: "Ape Hangers",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "£££",
        Category: "Bar / Nightclub",
        Address: "66-68 Clemens St",
    },
    {
        Name: "BeerTorrent Taproom",
        Location: "Leamington Spa",
        Ratings: "5",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "Creative Railway Arches, 1",
    },
    {
        Name: "The New Inn",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "195-197 Leam Terrace",
    },
    {
        Name: "The Jet Pub",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "67 Brunswick St",
    },
    {
        Name: "The Drawing Board",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "18 Newbold St",
    },
    {
        Name: "Las Iguanas - Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "Regent Court, 21 Livery St",
    },
    {
        Name: "The Greyhound",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "35, Kennedy Square",
    },
    {
        Name: "FIFTEEN",
        Location: "Leamington Spa",
        Ratings: "3.4",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "15 Spencer St",
    },
    {
        Name: "The Bowling Green Leamington Spa",
        Location: "Leamington Spa",
        Ratings: "4.3",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "18 New St",
    },
    {
        Name: "Tartine bistro & wine bar",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "30 Regent St",
    },
    {
        Name: "Hope & Anchor",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "£",
        Category: "Bar / Nightclub",
        Address: "41 Hill St",
    },
    {
        Name: "The Town House",
        Location: "Leamington Spa",
        Ratings: "4.5",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "2 George St",
    },
    {
        Name: "Oak House Sports and Social Club",
        Location: "Leamington Spa",
        Ratings: "4.6",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "87 Upper Holly Walk",
    },
    {
        Name: "Kasbah Nightclub",
        Location: "Coventry",
        Ratings: "3.3",
        PriceRange: "£",
        Category: "Bar / Nightclub",
        Address: "Primrose Hill St",
    },
    {
        Name: "Catch Twenty Two",
        Location: "Coventry",
        Ratings: "4",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "25A Hertford St",
    },
    {
        Name: "JJ's",
        Location: "Coventry",
        Ratings: "3.5",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "The Skydome, 2 Croft Rd",
    },
    {
        Name: "Scholars",
        Location: "Coventry",
        Ratings: "3.7",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "147 Far Gosford St",
    },
    {
        Name: "Glamorous Coventry",
        Location: "Coventry",
        Ratings: "3.9",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "Short St",
    },
    {
        Name: "Daddy Cools",
        Location: "Coventry",
        Ratings: "3",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "181 Spon St",
    },
    {
        Name: "Iglu Nightclub",
        Location: "Coventry",
        Ratings: "5",
        PriceRange: "£££",
        Category: "Bar / Nightclub",
        Address: "Spon St",
    },
    {
        Name: "S7VEN",
        Location: "Coventry",
        Ratings: "1.8",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "3 Lower Holyhead Rd",
    },
    {
        Name: "The Yard",
        Location: "Coventry",
        Ratings: "3.5",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "11-12 Bull Yard",
    },
    {
        Name: "Punyalans",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "1 Siddeley Ave",
    },
    {
        Name: "Club Heat",
        Location: "Coventry",
        Ratings: "3",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "7 City Arcade",
    },
    {
        Name: "Empire Coventry",
        Location: "Coventry",
        Ratings: "3.9",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "151 Far Gosford St",
    },
    {
        Name: "Scorpion Disco",
        Location: "Coventry",
        Ratings: "3.2",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "122 Elmsdale Ave",
    },
    {
        Name: "accoladex mobile disco and DJ",
        Location: "Coventry",
        Ratings: "4.2",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "Technology Centre, Puma Way",
    },
    {
        Name: "Impulse Bar",
        Location: "Coventry",
        Ratings: "3.7",
        PriceRange: "£",
        Category: "Bar / Nightclub",
        Address: "24-25 The Burges",
    },
    {
        Name: "Samoan Joe's",
        Location: "Coventry",
        Ratings: "4.1",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "Spon St",
    },
    {
        Name: "Micbox Ktv Coventry",
        Location: "Coventry",
        Ratings: "3.4",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "4 Hales St",
    },
    {
        Name: "Slug & Lettuce Coventry",
        Location: "Coventry",
        Ratings: "3.8",
        PriceRange: "££",
        Category: "Bar / Nightclub",
        Address: "County Hall, The Old",
    },
    {
        Name: "H20 Lounge",
        Location: "Coventry",
        Ratings: "4.4",
        PriceRange: "£££",
        Category: "Bar / Nightclub",
        Address: "10 Spon St",
    },
    {
        Name: "M & M Discos and karaoke",
        Location: "Coventry",
        Ratings: "5",
        PriceRange: "",
        Category: "Bar / Nightclub",
        Address: "24 Cheam Cl",
    },
];
